<template>
  <v-container dense>
    <v-dialog v-model="dialogStatus" :modal="true" width="50%" persistent>
      <v-card :loading="loading">
        <v-card-title class="grey lighten-2"
          >Per Mail senden
          <v-spacer></v-spacer>
          <v-icon @click="closeDialog">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" style="text-align: center;">
              <v-layout align-center justify-center column fill-height>
                <v-flex row align-center>
                  <v-progress-circular
                    v-if="!pdfsrc"
                    indeterminate
                    :size="50"
                    color="purple"
                    class=""
                  ></v-progress-circular>
                  <pdf v-if="pdfsrc" v-bind:src="pdfsrc" class="preview"> </pdf>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col cols="12" sm="6">
              <v-form v-model="isFormValid">
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-text-field
                      label="Empfänger"
                      name="mailto"
                      type="text"
                      :clearable="true"
                      required
                      dense
                      outlined
                      :rules="emailRules"
                      v-model="maildata.to"
                    />

                    <v-text-field
                      label="Betreff"
                      name="mailsubject"
                      type="text"
                      :clearable="true"
                      required
                      dense
                      outlined
                      :rules="requiredRule"
                      v-model="maildata.subject"
                    />

                    <v-textarea
                      name="message"
                      required
                      label="Nachricht"
                      :rules="requiredRule"
                      outlined
                      clearable
                      counter
                      v-model="maildata.message"
                    >
                    </v-textarea>

                    <v-checkbox
                      label="Kopie an mich senden"
                      v-model="maildata.copy"
                      color="green"
                    />
                    <v-btn class="ma-2" outlined @click="closeDialog"
                      >Abbrechen</v-btn
                    >
                    <v-btn
                      class="ma-2 white--text"
                      color="purple"
                      @click="sendMail"
                      :disabled="!isFormValid"
                      >Senden</v-btn
                    >
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import DataService from "@/services/DataService.js";
//import moment from "moment";

export default {
  name: "MailPreviewDialog",
  components: {
    pdf
  },
  data: () => ({
    dialogStatus: false,
    loading: false,
    pdfsrc: null,
    dataItem: null,
    maildata: {
      to: "",
      subject: "",
      message: "",
      copy: false,
      filename: ""
    },
    isFormValid: false,
    requiredRule: [(value) => !!value || "Dieses Feld wird benötigt!"],
    emailRules: [
      (v) => !!v || "E-mail wird benötigt!",
      (v) =>
        /.+@.+/.test(v) ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,5})+$/.test(v) ||
        "E-mail must be valid"
    ]
  }),
  methods: {
    setDataItem(item) {
      this.dataItem = item;
      this.customerEmail();
    },
    showDialog() {
      //this.maildata.to = ;
      this.maildata.subject = this.mailSubject;
      this.maildata.message = "";
      this.maildata.copy = false;
      this.maildata.filename = this.pdfFilename;
      /*  this.maildata.to = "";
      this.maildata.subject = "";
      this.maildata.message = "";
      this.maildata.copy = false;
      */
      this.pdfsrc = null;
      this.dialogStatus = true;
      this.loadPDFPreview();
    },
    closeDialog() {
      this.dialogStatus = false;
      this.pdfsrc = null;
    },
    sendMail() {
      this.$store
        .dispatch("mail/sendMail", {
          itemid: this.dataItem._id,
          maildata: this.maildata,
          mandant: this.dataItem.mandant
        })
        .then(() => {
          this.$notify({
            group: "dataline",
            type: "success",
            title: "Mail versendet",
            text: "Ihre Nachricht an: " + this.maildata.to + " wurde versendet."
          });
        })
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message
            });
          }
        });

      this.closeDialog();
    },
    loadPDFPreview() {
      this.loading = true;

      DataService.apiClient
        .get("/pdf/byobject/" + this.dataItem._id + "/" + this.dataItem.type)
        .then((response) => {
          // console.log("got data", response.data.data);
          const bytes = new Uint8Array(response.data.data.pdfData.data);
          const pdfdata = new Blob([bytes], {
            type: "application/pdf"
          });
          this.pdfsrc = URL.createObjectURL(pdfdata);
        })
        .catch((error) => {
          if (error.response && error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Fehler",
              text: error.message
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    customerEmail() {
      if (this.dataItem.customer.email) {
        return this.dataItem.customer.email;
      } else {
        this.$store
          .dispatch("contact/fetchContact", this.dataItem.customer._id)
          .then((contact) => {
            this.maildata.to = contact.data.data.email;
          })
          .catch((error) => {
            if (error.response.status != 401) {
              this.$notify({
                group: "dataline",
                type: "error",
                title: "Abfragefehler",
                text: "Kunden Email konnte nicht abgefragt werden."
              });
            }
            return "";
          });
      }
      return "";
      //return this.dataItem.customer.email;
    }
  },
  computed: {
    mandant() {
      return this.$store.getters["mandant/getMandantenList"].find(
        (item) => item._id == this.dataItem.mandant
      );
    },

    mailSubject() {
      if (this.dataItem.offernr) {
        return "Angebot " + this.dataItem.offernr;
      } else if (this.dataItem.confirmnr) {
        return "Auftragsbestätigung " + this.dataItem.confirmnr;
      } else if (this.dataItem.delivernr) {
        return "Lieferschein " + this.dataItem.delivernr;
      } else if (this.dataItem.billnr) {
        return "Rechnung " + this.dataItem.billnr;
      }
      return "";
    },
    pdfFilename() {
      if (this.dataItem.offernr) {
        return "Angebot " + this.dataItem.offernr + ".pdf";
      } else if (this.dataItem.confirmnr) {
        return "Auftragsbestätigung " + this.dataItem.confirmnr + ".pdf";
      } else if (this.dataItem.delivernr) {
        return "Lieferschein " + this.dataItem.delivernr + ".pdf";
      } else if (this.dataItem.billnr) {
        return "Rechnung " + this.dataItem.billnr + ".pdf";
      }
      return "";
    }
  },
  created() {}
};
</script>

<style scoped>
.preview {
  box-shadow: 4px 4px;
  border: 1px solid grey;
}
</style>
